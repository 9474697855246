@keyframes err{
  0%,
  100%{
    transform: translateX(0);
 }

  12.5%{
    transform: translateX(12px);
 }

  25%{
    transform: translateX(-12px);
 }

  37.5%{
    transform: translateX(8px);
 }

  50%{
    transform: translateX(-8px);
 }

  62.5%{
    transform: translateX(5px);
 }

  75%{
    transform: translateX(-5px);
 }

  87.5%{
    transform: translateX(3px);
 }
}

@keyframes spin{
  0%{
    transform: rotate(0);
 }
  100%{
    transform: rotate(360deg);
 }
}

@keyframes helix{
  0%{
    width: 4px;height: 4px;bottom: -4px;z-index: 10;
    background-color: #00BE6E;
  }
  25%{
    width: 2px;height: 2px;
    background-color: rgba(222, 252, 236, 0.8);
  }
  50%{
    width: 4px;height: 4px;bottom: 100%;z-index: 20;
    background-color: #00BE6E;
  }
  75%{
    width: 6px;height: 6px;
    background-color: #FF7AB7;
  }
  100%{
    width: 4px;height: 4px;bottom: -4px;
    background-color: #00BE6E;
  }
}

@keyframes helix_reversed{
  0%{
    width: 4px;height: 4px;bottom: 100%;z-index: 20;
    background-color: #00BE6E;
  }
  25%{
    width: 6px;height: 6px;
    background-color: #FF7AB7;
  }
  50%{
    width: 4px;height: 4px;bottom: -4px;z-index: 10;
    background-color: #00BE6E;
  }
  75%{
    width: 2px;height: 2px;
    background-color: rgba(222, 252, 236, 0.8);
  }
  100%{
    width: 4px;height: 4px;bottom: 100%;
    background-color: #00BE6E;
  }
}

@keyframes helix_bar{
  0%{height: 14px}
  25%{height: 6px}
  50%{height: 14px}
  75%{height: 6px}
  100%{height: 14px}
}

#root + iframe{display: none}

#appleid-signin>div{display: none}

.editerBox img{display:inline;border:0}
.editerBox table{width: auto;max-width: 100%;font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;}
.editerBox .table{width: 100%;margin-bottom: 1rem;color: #212529;background-color: transparent;}
.editerBox th,.editerBox td{padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;}
.editerBox thead th {vertical-align: bottom;border-bottom: 2px solid #dee2e6;}
.editerBox tbody + tbody {border-top: 2px solid #dee2e6;}
.editerBox .table-sm th,.editerBox .table-sm td{padding: .75rem;}
.editerBox .table-bordered{border: 1px solid #dee2e6;}
.editerBox .table-bordered th,
.editerBox .table-bordered td{border: 1px solid #dee2e6;} 
.editerBox .table-bordered thead th,.editerBox .table-bordered thead td{border-bottom-width: 2px;}
.editerBox .table-borderless th,
.editerBox .table-borderless td,
.editerBox .table-borderless thead th,
.editerBox .table-borderless tbody + tbody{border: 0}

.editerBox blockquote{border-left: 5px solid #D8D8D8;background: white;padding-left: 10px;}
.editerBox pre{display: block;padding: 9.5px;margin: 0 0 10px;font-size: 13px;line-height: 1.42857143;color: #333;word-break: break-all;word-wrap: break-word;background-color: #f5f5f5;border: 1px solid #ccc;border-radius: 4px;}
.editerBox p{margin-bottom: 1rem;margin-top: 0;}
.editerBox .h1, .editerBox .h2, .editerBox .h3, .editerBox .h4, .editerBox .h5, .editerBox .h6, .editerBox h1, .editerBox h2, .editerBox h3, .editerBox h4, .editerBox h5, .editerBox h6{margin-bottom: 0.5rem;}

/*google_translate*/
#google_translate_element{display: none}
.skiptranslate{display: none !important}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc{display: none}
font+br{display: none}
font{letter-spacing: -0.65px}
body{top:0 !important}
.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q{background-color: transparent;box-shadow: 0 0 0 transparent}
i{font-style: normal}

/*zendesk*/
#launcher{display: none}
#launcher + iframe{display: none}

/*basic*/
.pageSizing.gBg{background-color: rgba(222, 252, 236, 0.7)}
.pageSizing.gyBg{background: #F4F5F6}
.pageSizing.flexCenter{display: flex;flex-direction: column;align-items: center;justify-content: center}
.pageSizing.bottomBtn{padding-bottom: 114px}
.pageSizing.bottomBtnText{padding-bottom: 190px}
.pageSizing.headerSet{padding-top: 54px}
.pageSizing.fixedPage{position: fixed;top: 0;left: 0;width: 100%;height: 100%;overflow-y: auto;min-height: 0;z-index: 10}

.popup_cover{width: 100%;height: 100%;background: rgba(0, 0, 0, 0.6);opacity: 0;transition: opacity 0.2s}
.headerTapPage{padding-top: calc(54px + 49px) !important}
.bottomChkBtnPage{padding-bottom: 150px !important}

/*bottomErrMsg*/
.bottomErrMsg{position: fixed;bottom: 114px;transform: translateX(-50%);max-width: 420px;text-align: center;padding: 10px;color: #fff;font-size: 13px;font-weight: 500;opacity: 0;background: rgba(58, 58, 58, 0.8);border-radius: 100px;left: 50%;width: calc(100% - 40px);display: none;transition: opacity 0.2s;z-index: 9999}
.bottomErrMsg.active{opacity: 1}

/*loarding*/
.loadingBox{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9999;display: none}
.loadingBox.active .popup_cover{transition: opacity 0s;opacity: 1}
.loading{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%)}
.loading_dotBox{position: relative;display: flex;justify-content: center;align-content: center;width: 75px;height: 20px}
.loading_dot{position: relative;flex: 1;display: flex;justify-content: center;align-content: center;margin: 0 4px;height: 20px;width: 2px}
.loading .loading_dot:first-of-type{margin-left: 0}
.loading .loading_dot:last-of-type{margin-right: 0}
.loading_dot::before,.loading_dot::after{content: "";position: absolute;bottom: -4px;display: block;width: 4px;height: 4px;background: #00A05D;border-radius: 100%;box-shadow: 1px 1px 4px rgba(255,255,255,.15);animation: helix 2s ease-in-out infinite}
.loading_dot::after{bottom: 100%;animation: helix_reversed 2s ease-in-out infinite}
.loading_dot i{position: absolute;z-index: 25;align-self: center;width: 2px;height: 8px;background: rgba(255,255,255,.25);animation: helix_bar 2s ease-in-out infinite}
.loading_dot2::before, .loading_dot2::after, .loading_dot2 i{animation-delay: 0.08s}
.loading_dot3::before, .loading_dot3::after, .loading_dot3 i{animation-delay: 0.16s}
.loading_dot4::before, .loading_dot4::after, .loading_dot4 i{animation-delay: 0.24s}
.loading_dot5::before, .loading_dot5::after, .loading_dot5 i{animation-delay: 0.32s}
.loading_dot6::before, .loading_dot6::after, .loading_dot6 i{animation-delay: 0.4s}
.loading_dot7::before, .loading_dot7::after, .loading_dot7 i{animation-delay: 0.48s}
.loading_dot8::before, .loading_dot8::after, .loading_dot8 i{animation-delay: 0.56s}
.loading_dot9::before, .loading_dot9::after, .loading_dot9 i{animation-delay: 0.64s}

/*header*/
.header{position: fixed;top: 0;left: 0;width: 100%;height: 54px;z-index: 1000}
.headerSection{height: 100%;padding: 0 20px;position: relative;background: #fff}
.headerSection.notBg{background: transparent}
.btnHeaderRight{position: absolute;right: 20px;top: 50%;transform: translateY(-50%)}
.btnHeaderRightItem{display: block}
.btnHeaderLeft{position: absolute;left: 20px;top: 50%;transform: translateY(-50%)}
.headTitle{font-size: 14px;font-weight: 800;padding: 0 82px;display: flex;width: 100%;height: 54px;align-items: center;gap: 4px}
.headTitle.centerTitle{text-align: center;justify-content: center}
.btnHeaderRightLink{font-size: 14px;font-weight: 700;color: #ACACAC;text-decoration: underline;text-align: right}

/*menuHeader*/
.menuHeaderSection{display: flex;justify-content: space-between;align-items: center;gap: 20px}
.btnMenuOpen{width: 21px;height: 18px;position: relative}
.headerSectionLeft{display: flex;align-items: center}
.headerSectionRight{display: flex;align-items: center;justify-content: flex-end}
.btnMenuOpen span{display: block;width: 100%;height: 2px;background: #1e1e1e;border-radius: 10px;position: absolute;transition: transform 0.2s, opacity 0.2s}
.btnMenuOpen span:nth-child(1){top: 0;left: 0}
.btnMenuOpen span:nth-child(2){top: calc(50% - 1px);left: 0}
.btnMenuOpen span:nth-child(3){bottom: 1px;left: 0}

.btnMenuOpen.active span:nth-child(1){transform: translateY(8px) rotate(45deg)}
.btnMenuOpen.active span:nth-child(2){opacity: 0}
.btnMenuOpen.active span:nth-child(3){transform: translateY(-7px) rotate(-45deg)}
.btnHome{margin: 0 0 0 10px;width: 72px}
.btnHome img{width: 100%}
.btn_headerRight{position: relative}
.btn_headerRight + .btn_headerRight{margin: 0 0 0 16px}
.btn_headerRight.active::before{content: "";position: absolute;top: 0;right: 0;width: 5px;height: 5px;border-radius: 100%;background: #f44d4d;transform: translate(50%,-50%)}
.sideMenu_section{background: rgba(243, 243, 243, 0.5);padding: 20px 20px 30px}
.sideMenuBox{background: #fff;border: 1px solid #F3F3F3;border-radius: 10px;padding: 20px}
.sideMenuLogin_title{text-align: center;font-size: 15px;font-weight: 800;margin: 0 0 15px}
.sideMenu_mainLink{margin: 10px 0 0;display: flex;justify-content: space-between;gap: 10px}
.sideMenu_mainLink .sideMenuBox{width: calc(50% - 5px)}
.sideMenuBox>div>img{margin: 0 auto}
.sideMenuBoxTitle{text-align: center;margin: 10px 0 0;font-size: 13px;font-weight: 800}

.sideMenu_mainLink + div{margin: 20px 0 0}
.menuItem{display: flex;width: 100%;align-items: center;justify-content: space-between;padding: 17px 0;text-align: left}
.menuItem.disabled{width: calc(100% + 40px);margin-left: -20px;padding: 17px 20px;background: #F9F9F9}
.menuItem.disabled .menuItemName{color: #838383}
.menuItemName{font-size: 13px;font-weight: 800}
.sideMenu_tapBox{margin: 24px 0 0}
.menuBoxTitle{font-size: 16px;font-weight: 800;margin: 0 0 10px}
.inputTapBox{display: flex;justify-content: space-between;gap: 8px;flex-wrap: wrap}
.inputTapItem{width: calc(50% - 4px);box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);border-radius: 14px;padding: 16px;font-size: 14px;font-weight: 700;border: 1px solid transparent}
.inputTapItem.active{color: #FF7AB7;font-weight: 800;border-color: #FF7AB7}
.sideMenu_userList{padding: 20px 20px 50px}
.sideMenu{position: fixed;top: 54px;height: calc(100% - 54px);width: 100%;overflow-y: auto;left: -101%;transition: left 0.2s;background: #fff}
.sideMenu.active{left: 0}

.menuItemNameBox{display: flex;align-items: center;gap: 6px}
.menuItemIcon{background: #FF7AB7;border-radius: 49px;font-size: 11px;color: #fff;font-weight: 800;padding: 1px 5px}
.menuItemSubItemBox{display: flex;align-items: center;justify-content: flex-end;gap: 4px}
.menuSubItemText{color: #ACACAC;font-size: 13px;font-weight: 800;text-align: right}
.menuSubItemText.bColor{color: #1E1E1E}
.menuSubItemText.gColor{color: #00BE6E}

.sideMenu_profileArea{display: flex;justify-content: space-between;align-items: center;margin: 0 0 10px}
.sideMenu_profileBox{display: flex;align-items: center;gap: 14px;width: calc(100% - 34px)}
.sideMenu_profileImg{background-repeat: no-repeat;background-size: cover;background-position: center;width: 60px;height: 60px;border-radius: 100%;min-width: 60px}
.sideMenu_profile_name{font-weight: 800;font-size: 15px}
.sideMenu_profile_email{color: #acacac;font-size: 13px;font-weight: 800;margin: 3px 0 0}
.sideMenu_profileInfoBox{width: calc(100% - 74px)}

/*footer*/
.footerSection{background: #5D5D5D;padding: 25px 20px 30px}
.footerArea{margin: 25px 0 0}
.footerSnsBox{display: flex;align-items: center;gap: 6px}
.footerMenuBox{margin: 12px 0 0;display: flex;flex-wrap: wrap;gap: 10px;align-items: center}
.footerMenuBox>span{width: 1px;height: 10px;background: #838383;display: block}
.footerMenuItem{color: #F3F3F3;font-size: 13px;font-weight: 700}
.footer_copyrightTitle{color: #F3F3F3;font-size: 13px;font-weight: 800;margin: 10px 0 0}
.footer_copyright{color: #ACACAC;font-size: 12px;margin: 4px 0 0}
.footerText{margin: 20px 0 0;color: #C7C7C7;font-size: 12px;line-height: 21px}
.footerText a{color: #C7C7C7;font-size: 12px;line-height: 21px;text-decoration: underline}

.footerSection .inputName{color: #C7C7C7;font-weight: 400;font-size: 12px}
.footerSection .inputItem{box-shadow: none;background: transparent;border: 1px solid #838383;color: #fff;padding: 13px 42px}
.footerSection .inputBox{position: relative}
.footerSection .customSelectInputBox.active::after{transform: translateY(-50%) rotate(180deg)}
.footerSection .inputBox::after{content: "";width:20px;height:20px;position: absolute;background: url(/assets/images/basic/subSel_icon.svg) no-repeat center;border-radius: 100%;top: 50%;transform: translateY(-50%);right: 12px}
.inputItemBox.en .inputBox::before{content: "";width:24px;height:24px;position: absolute;background: url(/assets/images/img/en.svg) no-repeat center;background-size: 66%;background-color: rgba(255,255,255,0.1);border-radius: 100%;top: 50%;transform: translateY(-50%);left: 10px}
.inputItemBox.id .inputBox::before{content: "";width:24px;height:24px;position: absolute;background: url(/assets/images/img/id.svg) no-repeat center;background-size: 66%;background-color: rgba(255,255,255,0.1);border-radius: 100%;top: 50%;transform: translateY(-50%);left: 10px}
.customSelect_optionBox{border: 1px solid #838383;border-radius: 8px;margin: 5px 0 0;background: #5D5D5D;overflow: hidden;display: none;max-height: 200px;overflow-y: auto}
.customSelect_optionBox.active{display: block}
.customSelect_option{display: block;color: #c7c7c7;width: 100%;text-align: left;padding: 10px 12px}
.customSelect_option.active{background: #535252;color: #fff}

.inputItemBox.productOptionItemBox{margin: 0 0 15px}
.productOptionItemBox .inputItem{box-shadow: none;border: 1px solid #D8D8D8;font-size: 14px;background: url(/assets/images/basic/sel.svg) no-repeat right 11px center}
.optionSelect_optionBox{display: none;border: 1px solid #D8D8D8;background: #fff;margin: -1px 0 0;border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;max-height: 200px;overflow: hidden;overflow-y: auto}
.optionSelectInputBox.active .inputItem{border-bottom-right-radius: 0;border-bottom-left-radius: 0}
.optionSelect_optionBox button{display: flex;align-items: center;justify-content: space-between;padding: 13px 12px;width: 100%;border-bottom: 1px solid #F3F3F3}
.optionSelect_optionBox button.active{background: rgba(243, 243, 243, 0.5)}
.optionSelect_optionName{font-size: 13px;color: #5D5D5D}
.optionSelect_optionPrice{text-align: right;color: #5D5D5D;font-weight: 800;font-size: 13px}
.optionSelect_optionBox button.active .optionSelect_optionName,.optionSelect_optionBox button.active .optionSelect_optionPrice{color: #C7C7C7}
.optionSelect_optionBox.active{display: block}

/*btn*/
.pageBtn{background: #00A05D;border: 1px solid #00A05D;height: 54px;width: 100%;display: block;border-radius: 51px;color: #fff;font-size: 15px;font-weight: 800;padding: 0 10px}
.pageBtn.line{background: #fff;color: #00A05D}
.pageBtn:disabled{background: #D8D8D8;border-color: #d8d8d8;cursor: auto}
.pageBtn.line:disabled{background: #fff;border-color: #C7C7C7;color: #C7C7C7}
.pageBtn.lineW,.pageBtn.lineW:disabled{background: transparent;color: #fff;border-color: #fff}
.pageBtn.lineW2{background: transparent;color: #fff;border: 2px solid #fff}
.btn_box.col2{display: flex;justify-content: space-between;gap: 10px}
a.pageBtn{display: flex;align-items: center;justify-content: center;text-align: center}

.pageBtn.nonBtn{text-decoration: underline;background: transparent;border-color: transparent;height: 44px}
.pageBtn.snsBtn{border-radius: 14px;padding: 0 50px;border:0}
.pageBtn.col2{width: calc(50% - 4px)}
.pageBtn_box{padding: 20px;background: #fff;position: fixed;left: 0;bottom: 0;width: 100%;z-index: 20}
.pageBtn_box.notBg{background: transparent}
.pageBtn_box.col2{display: flex;justify-content: space-between;gap: 10px}
.btn_subBox.col2{display: flex;justify-content: space-between;gap: 8px}
.chk_item + .btn_subBox{margin: 20px 0 0}

.textBtn{display: flex;align-items: center;justify-content: center;gap: 3px;margin: 0 auto}
.textBtn_contents{font-weight: 800;font-size: 14px;text-decoration: underline}

.minPageBtn{width: 54px;height: 54px;min-width: 54px;display: flex;align-items: center;justify-content: center;border-radius: 14px;background: #f3f3f3}
.sharingInput{position: fixed;top: 0;left: 0;transform: translate(-101%,-101%)}

.minBtn{background: #00A05D;padding: 4px 10px;border-radius: 43px}
.minBtn_contents{font-size: 14px;color: #fff;font-weight: 800}
.nowrap .minBtn_contents{white-space: nowrap}

/*subLine*/
.subLine{display: block;height: 6px;background: #F3F3F3;width:100%}
.subLine.pageFull{width: 100vw;transform: translateX(-20px)}

/*category*/
.categoryBox{width: 100vw;margin: 15px 0 15px -20px;padding: 0 20px;display: flex;overflow-x: auto}
.categoryItem{padding: 3px 8px;white-space: nowrap;display: inline-block;color: #ACACAC;font-size: 13px;font-weight: 700;border-radius: 43px}
.categoryItem.active{background: #FF7AB7;color: #fff;font-weight: 800}

/*accordion*/
.accordionBox{border: 2px solid transparent;border-radius: 14px;background: #fff}
.accordionBox.active{border-color: #00BE6E}
.accordionBox + .accordionBox{margin: 8px 0 0}
.accordionTitleBox{width: 100%;display: flex;padding: 15px}
.accordionIcon{font-size: 14px;font-weight: 800;min-width: 25px;text-align: left;line-height: 1.8}
.questionIcon{color: #00be6e}
.accordionTextBox{width: calc(100% - 25px - 25px);padding: 0 20px 0 0}
.accordionTitle{text-align: left;width: 100%;font-size: 14px;font-weight: 800;line-height: 1.8}
.accordionTextBox + img{min-width: 25px;transition: transform 0.2s}
.accordionBox.active .accordionTextBox + img{transform: rotate(180deg)}
.answerText{padding: 0 15px 15px 40px;display: none}
.accordionText{font-size: 13px;line-height: 21px}
.answerText .accordionTextBox{padding: 0;width: 100%}

/*input*/
.inputItemBox{margin: 20px 0 0}
.inputItemBox.marginUp{margin: 30px 0 0}
.inputName{font-weight: 800;font-size: 13px;margin: 0 0 10px;position: relative}
.inputName .essential_icon{color: #00A05D;}
.inputItem{box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);border: 1px solid transparent;border-radius: 8px;width: 100%;padding: 13px 15px;font-size: 15px;color: #1E1E1E}
.err .inputItem{animation: err 0.3s;border-color: #F44D4D}
.selItem .inputItem{color: #1E1E1E;background: url(/assets/images/basic/sel.svg) no-repeat center right 10px;background-color: #fff;padding-right: 40px}
.selItem .inputItem:disabled{color: #838383;background-color: rgba(0,0,0,0.1)}
.inputComBox{position: relative}
.inputComBox .inputItem{padding-right: 145px}
.btn_inputSubBtnBox{position: absolute;right: 13px;top: 50%;transform: translateY(-50%);display: flex;align-items: center;justify-content: flex-end;gap: 6px}
.btn_inputSubBtn{min-width: 67px;min-height: 26px;background: #c7c7c7;border-radius: 49px;color: #fff;font-size: 13px;padding: 4px;font-weight: 700}
.btn_inputSubBtn.pBg{background: #FF7AB7}
.btn_inputSubBtn.wSizeUp{min-width: 135px}
.cetifiedTimer{transform: translateY(1px);color: #4DA9FF;font-size: 15px}
.cetifiedTimer:last-child{right:15px}
.cetifiedInput .inputItem{padding-right: 187px}
.errMsg{color: #F44D4D;margin: 4px 0 0;font-size: 12px;font-weight: 700}
.inputCaption{margin: 4px 0 0;font-size: 12px;color: #ACACAC;font-weight: 700}
.inputSet{position: relative}
.notReadOnlyColor .inputItem{color: #1e1e1e;background: #fff}

.inputUnitComBox .inputItem{padding-right: 85px}
.inputUnit{font-size: 15px;font-weight: 700;color: #5D5D5D;position: absolute;top: 50%;transform: translateY(-50%);right: 15px}

.chk_item{padding: 2px 0;display: flex;align-items: center;justify-content: space-between;gap: 15px}
.chk_item:not(.allChkItem) + .chk_item{margin: 14px 0 0}
.chk_item.allChkItem{border-bottom: 1px solid #F3F3F3;padding: 0 0 10px;margin: 0 0 10px}
.chk_item.allChkItem .chk_item_text{font-weight: 800;font-size: 15px}
.chk_item.allChkItem label::before{width: 24px;height: 24px}
.chk_item label{position: relative;padding: 0 0 0 30px;width: calc(100% - 34px);font-weight: 700}
.chk_item label::before{content: "";position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 20px;height: 20px;background: url(/assets/images/basic/chk_off.svg) no-repeat center;background-size: 100%}    
.chk_item input:checked + label::before{background-image: url(/assets/images/basic/chk_on.svg)}
.chk_item_text{color: #3a3a3a;font-size: 13px}
.chkBoxBtn{color:#ACACAC;text-decoration: underline;font-size: 13px;font-weight: 700;text-align: right;white-space: nowrap}
.inputItemBox + .chk_item{margin-top: 16px}

.toggleBox label{cursor: pointer;display: flex;font-weight: 500;align-items: center;justify-content: space-between}
.toggleBox label span{width: 38px;height: 23px;background: #ACACAC;border-radius: 23px;position: relative;transition: background 0.3s}
.toggleBox label span::before{content: "";position: absolute;top: 50%;transform: translateY(-50%);left: 3px;background: #fff;border-radius: 100%;width: 16px;height: 16px;box-shadow: 0 0 3px 0 rgba(0,0,0,0.25);transition: left 0.3s cubic-bezier(0.89, 0.11, 0.09, 0.89)}
.toggleBox .toggleChk:checked + label span{background: #00BE6E}
.toggleBox .toggleChk:checked + label span::before{left: calc(100% - 19px)}

.textareaItem{display: block;resize: none;min-height: 130px;box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);border: 1px solid transparent;border-radius: 8px;width: 100%;padding: 13px 15px;font-size: 15px;color: #1E1E1E}
.textareaCount{font-size: 13px;width: 100%;text-align: right;padding: 8px 0 0;color: #ACACAC}
.textAreaBtnComBox{position: relative}
.textAreaBtnComBox .textareaItem{background: #fff;color: #1E1E1E;padding-right: 90px}
.textAreaBtnComBox .btn_inputSubBtnBox{top: 14px;transform: translateY(0)}
.minSize .textareaItem{min-height: 91px}

.inputFileList{width: 100vw;transform: translateX(-20px);padding: 0 20px;overflow-x: auto;display: flex;gap: 12px}
.inputFileBox .inputFileList label{min-width: 70px;width: 70px;height: 70px;padding: 0;border: 1px solid #E7E7E7;border-radius: 15px;display: flex;align-items: center;justify-content: center}
.fileItemMultipleItem{background-repeat: no-repeat;background-size: cover;background-position: center;width: 70px;height: 70px;min-width: 70px;border-radius: 15px;position: relative}
.fileItemMultipleItemDel{position: absolute;top:5px;right:5px;z-index: 1}

.react-datepicker-wrapper{width: 100%}
.datePicerInputBox .inputItem{width: 100%;background: url(/assets/images/basic/cal.svg) no-repeat right 15px center;background-color: #fff;color: #1E1E1E}

.inputSubCaptionItem{position: relative}
.inputSubCaptionItem .inputItem{padding-left: 33px}
.inputSubCaption_text{font-size: 15px;position: absolute;left: 15px;top: 50%;transform: translateY(-50%)}

.inputItem.hideInputItem{display: none}
.inputPItem{color:#acacac !important;font-weight:500;cursor: pointer}
.inputPItem.onText{color: #1E1E1E !important;font-weight: 400}
.inputPItem.disabled{cursor: auto;opacity: 1;color:#838383;background: rgba(0,0,0,0.1)}

/*datepicker*/
.react-datepicker__tab-loop{width: 100%}
.react-datepicker__tab-loop__start{display: none}
.react-datepicker-popper{padding: 0;width: 100%;z-index: 10;max-width: 320px;z-index: 25}
.react-datepicker{box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);border: 1px solid #838383;border-radius: 8px;font-size: 0.875em;width: 100%;padding: 20px 20px 30px;display: flex;align-items: flex-start;justify-content: space-between;gap: 20px}
.react-datepicker__day-names{margin: 0}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle{display: none}
.react-datepicker-popper[data-placement^=top]{padding-bottom: 6px}
.react-datepicker-popper[data-placement^=bottom]{padding-top: 6px}
.react-datepicker__month-container{width: 100%}
.react-datepicker__header{background: transparent;border: 0;padding: 0 0 0}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{font-weight: 700;font-size: 13px;margin: 0 0 14px;color: #1e1e1e}
.react-datepicker__day-names{font-size: 10px;display: flex;align-items: center;justify-content: space-between}
.react-datepicker__month{margin: 0}
.react-datepicker__day-names, .react-datepicker__week{display: flex;align-items: center;justify-content: space-between}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{min-width: 14.28%;margin: 0;height: 32px;display: flex;align-items: center;justify-content: center;font-weight: 500;color: #1E1E1E;line-height: 1}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled{color: #D1D1D1}
.react-datepicker__navigation{width: 21px;height: 21px;background-size: 100%;top: 16px}
.react-datepicker__navigation:disabled{opacity: 0.4}
.react-datepicker__navigation--previous{left: 26px;background: url(/assets/images/basic/cal_left.svg) no-repeat center}
.react-datepicker__navigation--next{right: 26px;background: url(/assets/images/basic/cal_right.svg) no-repeat center}
.react-datepicker__navigation-icon{display: none}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{background: transparent;position: relative}
.react-datepicker__day.react-datepicker__day--in-range,.react-datepicker__day.react-datepicker__day--in-selecting-range:hover{background: rgba(30, 30, 30, 0.05)}
.react-datepicker__day:hover:not(.react-datepicker__day--disabled):not(.react-datepicker__day--outside-month)::before{content: "";position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);height: 100%;width: 32px;border-radius: 100%;border: 1px solid #00A05D}
.react-datepicker__day--disabled,.react-datepicker__day--outside-month{background: transparent !important}
.react-datepicker__day span{position:relative;z-index: 1}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){background: transparent}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range):not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month){border-radius: 0;background: rgba(30, 30, 30, 0.05)}
.react-datepicker__day--in-range:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month),.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month){border-radius: 0;background: rgba(30, 30, 30, 0.05)}
.react-datepicker__day--selected:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month),
.react-datepicker__day--range-end:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month),
.react-datepicker__day--selecting-range-start:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month),
.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month){position: relative;background: transparent !important}
.react-datepicker__day--selected:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month)::before,
.react-datepicker__day--range-end:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month)::before,
.react-datepicker__day--selecting-range-start:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month)::before,
.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month)::before{content: "";position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);height: 100%;width: 32px;border: 1px solid #00A05D;border-radius: 100% !important;background: #00A05D !important}
.react-datepicker__day--selected span,.react-datepicker__day--range-end span,.react-datepicker__day--selecting-range-start span,.react-datepicker__day--selecting-range-end span{color:#fff}
.react-datepicker__day--keyboard-selected{background: transparent !important}
.react-datepicker__triangle{display: none}

/*textItem*/
.textItem{display: flex;align-items: flex-start;justify-content: space-between;gap: 20px}
.textItem + .textItem{margin: 10px 0 0}
.textItem_title{color: #5D5D5D;font-size: 14px;font-weight: 700}
.textItem_contents{font-weight: 700;font-size: 14px;color: #5D5D5D}

/*popup*/
.popup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.popup .popupSetion{overflow: hidden;padding: 25px 20px 20px;overflow-y: auto;position: fixed;top: 0;left: 0;width: 100%;background: #fff;background: #fff;top: 50%;left: 50%;transform: translate(-50%,-50%);z-index: 1;border-radius: 10px;width: calc(100% - 40px);max-width: 280px;max-height: calc(100% - 40px);opacity: 0;transition: opacity 0.2s}
.btnPopupSetion .popupSetion{padding-bottom: calc(62px + 1.313em + 20px)}
.popup.active .popup_cover{opacity: 1}
.popup.active .popupSetion{opacity: 1}
.popupTitle{font-weight: 800;font-size: 18px;margin: 0 0 10px}
.popupText{font-size: 14px;color: #5D5D5D}
.popupText.rColor{color: #F44D4D}
.popupText .rColor{color: #F44D4D}
.popup_btnBox{display: flex;width: 100%;justify-content: flex-end;gap: 10px;margin: 25px 0 0}
.btn_popup{padding:5px;background: #00A05D;border: 1px solid #00A05D;color: #fff;height: 36px;min-width: 78px;border-radius: 52px;display: flex;align-items: center;justify-content: center;font-size: 13px;font-weight: 800}
.btn_popup.line{background: #fff;color: #00A05D}
.btn_popup[disabled=true]:not(.btn_popup[disabled=false]),.btn_popup:disabled:not(.btn_popup[disabled=false]){background: #D8D8D8;border-color: #d8d8d8;cursor: auto}
.btn_popup.line[disabled=true]:not(.btn_popup[disabled=false]),.btn_popup.line:disabled:not(.btn_popup[disabled=false]){background: #fff;border-color: #C7C7C7;color: #C7C7C7}

/*rightPopup*/
.rightPopupClose{position: absolute;top: 50%;right: 20px;transform: translateY(-50%)}
.rightPopup{background: #fff;position: fixed;top: 0;right: 0;width: 100%;height: 100%;transform: translateX(100%);z-index: 9000;transition: transform 0.2s cubic-bezier(0.89, 0.11, 0.09, 0.89)}
.rightPopup.active{transform: translateX(0)}
.rightPopup .popup_cover{display: none}
.rightPopup_section{height: 100%;background: #fff}
.rightPopup_header_section{height: 54px;position: relative;padding: 0 20px;display: flex;align-items: center;z-index: 10}
.rightPopup_contents{height: calc(100% - 54px);overflow-y: auto;padding: 0}
.rightPopup_title{font-size: 14px;font-weight: 800;padding: 0 25px;display: flex;width: 100%;height: 54px;align-items: center;text-align: center;justify-content: center;letter-spacing: -1px;line-height: 1.2}
.bPopup .rightPopup_title{color:#fff}
.termsText{padding: 20px;font-size: 15px;line-height: 25px;white-space: pre-wrap}
.rightPopup{background: rgba(53, 53, 53, 0.9);backdrop-filter: blur(5.5px);-webkit-backdrop-filter: blur(5.5px)}

.rightPopup.bottomBtnText .rightPopup_contents{padding-bottom: 160px}
.rightPopup.bottomBtn .rightPopup_contents{padding-bottom: 110px}
.rightPopup.sidePadding .rightPopup_contents{padding-left: 20px;padding-right: 20px}

/*selPopup*/
.selPopup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.selPopupSetion{border-top-left-radius: 24px;border-top-right-radius: 24px;padding: 24px 0 0;background: #fff;position: absolute;bottom: 0;left: 0;z-index: 1;width: 100%;transform: translateY(100%);transition: transform 0.2s cubic-bezier(0.13, 0.91, 0.11, 0.91)}
.selPopupTextBox{padding: 0 20px 10px}
.selPopup_selBox{padding: 0 20px 30px;max-height: 300px;overflow-y: auto}
.selPopup_sel{padding: 15px 0;text-align: left;border-radius: 15px;border: 0;display: block;width: 100%;font-size: 15px;font-weight: 700}
.selPopup.active .popup_cover{opacity: 1}
.selPopup.active .selPopupSetion{transform: translateY(0)}
.selPopup_sel.active{font-weight: 800;color: #00A05D;background: url(/assets/images/basic/selPopup_active.svg) no-repeat center right}
.selPopup .popupHaeder{position: relative;padding: 0 0 18px}
.selPopup .popupHaederTitle{text-align: center;font-size: 15px;font-weight: 800;color: #5d5d5d;padding: 0 50px}
.popupClose{position: absolute;right: 20px;top: -2px}
.selPopup.selBgType .selPopup_selBox{padding-left: 0;padding-right: 0}
.selPopup.selBgType .selPopup_sel{padding-left: 20px;padding-right: 20px}
.selPopup.selBgType .selPopup_sel.active{background-color:#EFFDF5;border-radius: 0;background-position: right 20px center}

/*toastPopup*/
.toastPopup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.toastPopup .popupSetion{display: flex;flex-direction: column;max-height: calc(100% - 20px);background: #fff;position: absolute;bottom: 0;left: 0;z-index: 1;border-top-left-radius: 30px;border-top-right-radius: 30px;padding: 20px;width: 100%;transform: translateY(100%);transition: transform 0.2s cubic-bezier(0.13, 0.91, 0.11, 0.91)}
.toastPopup.active .popup_cover{opacity: 1}
.toastPopup.active .popupSetion{transform: translateY(0)}
.toastPopup .popupHaeder{min-height: 36px;position: relative;padding: 0 0 15px;min-height: 36px}
.toastPopup .popupHaederTitle{text-align: center;padding: 0 35px;font-size: 15px;font-weight: 800;color: #5d5d5d}
.toastPopup .popupHaederTitle .gColor{color: #00A05D}
.toastPopup .popupContents{overflow-y: auto}
.toastPopup .popupClose{position: absolute;right: 0;top: -2px}
.toastPopup_btnBox{justify-content: space-between;min-height: 54px}
.toastPopup_btnBox .btn_popup{width: 100%;height: 54px;font-size: 15px}
.popup_btnChkBox{min-height: 20px;margin: 20px 0 0}
.popup_btnChkBox + .toastPopup_btnBox{margin-top: 20px}

.toastBasicContents{text-align: center;padding:10px 0}
.toastBasicContents_title{font-weight: 800;font-size: 20px}
.toastBasicContents_title .gColor{color:#00A05D}
.toastBasicContents_text{font-size: 15px;margin: 15px 0 0}

.toastPopup .popupHeadBtn{text-decoration: underline;font-size: 15px;font-weight: 700;color: #ACACAC;position: absolute;right: 0;top: 0;max-width: 67px;text-align: right}
.toastPopup .popupHeadBtn + .popupHaederTitle{padding: 0 72px}
.cookiePopupText{padding: 15px 0 0;font-size: 13px;line-height: 22px}
.cookiePopupText>button{text-decoration: underline;font-weight: 600}

.cookieArea{padding: 0 20px 135px}
.cookieTitleBox{display: flex;align-items: center;justify-content: space-between;min-height: 48px;padding: 10px 0;position: fixed;left: 0;padding: 10px 20px;width: 100%;top: 54px;background: #fff;z-index: 10}
.cookieTitle{font-weight: 700;font-size: 13px;color: #3A3A3A}
.cookieTitleCaption{font-size: 13px;font-weight: 700;color: #ACACAC}
.cookieText{padding: 58px 0 0}
/* .cookieText{padding: 21px 0 0;overflow-y: auto;max-height: calc(calc(var(--vh, 1vh) * 100) - 54px - 48px - 41px - 94px)} */
.cookeiSelPopup .swiper-pagination{background: #fff;padding: 20px 0 10px;position: fixed;bottom: 94px;width: 100%;z-index: 10;display: flex;align-items: center;justify-content: center;gap: 8px}
.cookeiSelPopup .swiper-pagination-bullet{background: #D8D8D8;width: 11px;height: 11px;min-width: 11px;border-radius: 100%}
.cookeiSelPopup .swiper-pagination-bullet-active{background: #00BE6E;opacity: 1}

/*headerTop*/
.headerTapBox{position: fixed;top: 54px;left: 0;width: 100%;height: 49px;background: #fff;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;box-shadow: 0 2px 20px 0 rgba(0,0,0,0.04);z-index: 999;display: flex;justify-content: space-between}
.headerTapItem{position: relative;width: 50%;color: #c7c7c7;font-size: 15px;font-weight: 800;display: flex;align-items: center;justify-content: center;gap: 10px;padding: 0 25px}
.headerTapItem.active{color: #1E1E1E}
.headerTapItem::before{content: "";height: 4px;width: 70%;left: 50%;transform: translateX(-50%);bottom: 0;position: absolute;border-radius: 50px}
.headerTapItem.active::before{background: #00A05D}

/*member*/
.memberPage{padding: 54px 20px 114px}
.memberPage.bottomContents{min-height: calc(var(--vh, 1vh) * 100);display: flex;flex-direction: column;align-items: center;justify-content: space-between}
.memberPage.gBg{background: #00BE6E}
.memberPage.notBtn{padding: 54px 20px 20px}
.memberPage.notTop{padding-top: 0}
.memberInput_section .pageBtn + .pageBtn{margin-top:10px}
.memberInput_section{width:100%}
.memberInput_section.certifiedContents{padding: 50px 0 0;text-align: center}
.pageBtn.apple{background: url(/assets/images/sns/apple.svg) no-repeat left 20px center;background-color: #1E1E1E;position: relative}
.appleSnsButton{position: absolute;top: 0;left: 0;width:100%;height:100%}
.pageBtn.google{background: url(/assets/images/sns/google.svg) no-repeat left 20px center;color: #1E1E1E;background-color: #fff}
.pageBtn.fb{background: url(/assets/images/sns/fb.svg) no-repeat left 20px center;background-color: #1877F2}
.logoBox{padding: 60px 0 20px}

.loginSubLinkBox{display: flex;align-items: center;justify-content: space-between;margin: 30px 0 0;padding: 0 0 10px}
.loginSubLink{font-weight: 700;font-size: 13px;text-align: center;width: calc(50% - 0.5px);padding: 0 10px}
.loginSubLinkBox span{background: #ACACAC;height: 8px;width: 1px;display: block}
.loginSubLinkBox.welCome_orderBox{border-top: 1px solid rgba(255, 255, 255, 0.3);padding: 30px 0 20px;margin: 25px 0 0}
.loginSubLinkBox.welCome_orderBox .loginSubLink{color: #fff}
.loginSubLinkBox.welCome_orderBox .loginSubLinkBox span{background: rgba(255, 255, 255, 0.3)}

.memberTitleBox{width: 100%;margin: 20px 0 0}
.memberTitle{font-size: 22px;font-weight: 800}
.memberTitle span{color: #00A05D;font-weight: 800}

.whatsPopupImg{margin: 15px auto 0}
.whatsPopupTitle{font-weight: 800;font-size: 18px;text-align: center;margin: 20px 0 0}
.whatsPopupText{font-size: 13px;color: #3a3a3a;margin: 10px 0 0;text-align: center}

.certifiedComBox{text-align: center;display: flex;align-items: center;justify-content: center;gap: 10px;flex-direction: column}
.certifiedComTitle{font-weight: 800;font-size: 22px}
.certifiedComTitle span{color: #00A05D}

.verificationLink{font-weight: 800;font-size: 14px;text-decoration: underline !important;margin: 25px 0 0;display: inline-block}
.verificationLinkOrder{margin: 15px 0 0}
.verificationLinkBox{text-align: center}

/*notItem*/
.notItemArea{padding: 30px 0;display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 18px}
.notItemArea.pageType{min-height: calc(calc(var(--vh, 1vh) * 100) - 54px)}
.notItemText{color: #c7c7c7;font-size: 15px;font-weight: 700;text-align: center}
.notItemArea.pageType.categoryType{min-height: calc(calc(var(--vh, 1vh) * 100) - 54px - 55px - 50px)}

/*mypage*/
.bgSection{padding: 54px 20px 50px;background: #F4F5F6;min-height: calc(var(--vh, 1vh) * 100);position: relative;z-index: 1}
.bgSectionCover{display: block;position: fixed;top: 0;left: 0;width: 100%;background: #F4F5F6;height: 100%}

.mypage_section{padding: 54px 20px 50px}
.mypage_section.notSide{padding-left: 0;padding-right: 0}
.mypage_section.bottomBtn{padding-bottom: 150px}
.menuBox{padding: 20px 20px 0}
.menuBox + .menuBox{border-top: 6px solid #f3f3f3}

.withdrawal_textBox{margin: 30px 0 40px}
.withdrawal_textBox>img{margin: 0 auto 16px}
.withdrawal_text{text-align: center;font-size: 14px;font-weight: 700;list-style: 1.7}
.withdrawal_text span{color: #F44D4D}

.alarmItem{background: #fff;border-radius: 20px;padding: 20px;margin: 12px 0 0}
.alarmItem_head{display: flex;align-items: center;justify-content: space-between;gap: 15px}
.alarmItem_head_textBox{display: flex;align-items: flex-start;gap: 8px;width:100%}
.alarmItem_name{font-size: 16px;font-weight: 800;width: calc(100% - 24px - 8px)}
.alarmItem_contents{margin: 16px 0;font-size: 12px}

.inquiryCaption{text-align: center;color: #ACACAC;font-weight: 700;font-size: 13px;margin: 0 0 14px}
.inquiryInput{margin-top: 30px}

.btn_csService{position: fixed;right: 20px;bottom: 20px;z-index: 20}
.btn_csServiceText{background: #FF7AB7;position: relative;border-radius: 5px;color: #fff;padding: 4px;font-weight: 800;font-size: 10px;transform: translateY(5px);width: 100%}
.btn_csServiceText:after{top: 100%;left: 50%;border: solid transparent;content: "";height: 0;width: 0;position: absolute;pointer-events: none;border-top-color: #FF7AB7;border-width: 4px;margin-left: -4px}

.profileEditBox{margin: 20px 0 0}
.btnProfileEdit{text-align: right;font-size: 12px;font-weight: 700;color: #acacac;max-width: 80px}
.btnProfileEdit.active{color: #3F97FE}

.profileTypeInput .inputItem{background: no-repeat center right 14px;padding-right: 54px;background-color: #F9F9F9;color: #1e1e1e}
.profileType_google .inputItem{background-image: url(/assets/images/sns/google_min.svg)}
.profileType_apple .inputItem{background-image: url(/assets/images/sns/apple_min.svg)}
.profileType_facebook .inputItem{background-image: url(/assets/images/sns/fb_min.svg)}

/*main*/
.pageSection{padding: 54px 0 0}
.pageSection.subPage{padding: 74px 20px 50px}
.pageSection.flexCenter{display: flex;flex-direction: column;align-items: center;justify-content: center}
.pageSection.bottomBtn{padding-bottom: 114px}
.pageSection.notHeader{padding-top: 0}
.mainVideoBox{width: 100%;padding: 0 0 56.15%;position: relative}
.mainVideoBox>iframe{position: absolute;top: 0;left: 0}
.mainVideoBox.fullSize{width: 100vw;margin-left: -20px}
.mainVideoBox video{position: absolute;top: 0;left: 0;width:100%;height:100%;object-fit: fill;display: block;background-color: #1a1a1a}

.mainSection{padding: 50px 20px}
.mainSectionTitle{text-align: center;font-size: 22px;font-weight: 800;line-height: 32px}
.mainSectionTitleCaption{margin: 10px 0 0;font-size: 15px;color: #5d5d5d;text-align: center}
.mainSectionTitleBox{margin: 0 0 30px}
.mainSectionTitleBox>img{margin: 0 auto 12px}
.mainSectionTitle span{color: #00BE6E}
.mainSection.mainProductSection{background: url(/assets/images/img/mainSectionBg.png) no-repeat center;background-size: cover}

.mainProductBtnBox{margin: 50px 0 0}
.mainProductBtnBox .pageBtn + .pageBtn{margin: 10px 0 0}

.mainProductItemArea{margin: 0 0 30px}
.productListBox.mainProductBox{border-color: transparent;background: rgba(255,255,255,0.8);padding: 20px}
.productList_name{font-size: 15px;font-weight: 700}
.productList_price{margin: 4px 0 0;font-size: 15px;color: #1e1e1e}
.subProductListBox{margin: 12px 0 0;border-radius: 14px;background: #fff;padding: 16px;display: flex;align-items: center;justify-content: space-between;gap: 15px}
.subProductList_name{color: #FF4FA0;font-size: 15px;font-weight: 800}
.subProductList_priceBox{display: flex;align-items: center;justify-content: flex-end;gap: 6px}
.subProductList_salePrice{color: #C7C7C7;font-size: 15px;font-weight: 700;text-decoration: line-through}
.subProductList_price{font-size: 15px;font-weight: 800}

.maingBgSection{background: #00BE6E}
.maingBgSection .mainSectionTitle{color: #fff}
.maingBgSection .mainSectionTitle>img{display: inline-block;vertical-align: middle;margin: 0 5px 0 0}
.mainService .swiper{overflow: initial}
.mainSlideItem{background: #fff;border-radius: 30px;padding: 15px 15px 25px}
.mainSlideItem_img{background-repeat: no-repeat;background-position: center;background-size: cover;width: 100%;padding: 0 0 107%;border-radius: 20px}
.mainSlideItem_name{font-size: 15px;font-weight: 700;margin: 22px 0 0;text-align: center}
.mainService{padding: 60px 0 0}
.mainService .swiper-pagination{position: absolute;top: -60px;left: 50%;z-index: 100;transform: translateX(-50%);display: flex;align-items: center;justify-content: space-between;gap: 19px}
.mainService .swiper-pagination-bullet{white-space: nowrap;color: #fff;opacity: 0.8;font-size: 13px;font-weight: 800;min-width: 70px;display: block;border-radius: 199px;padding: 6px;text-align: center;position: relative}
.mainService .swiper-pagination-bullet-active{background: #FF7AB7;opacity: 1}
.mainService .swiper-pagination-bullet + .swiper-pagination-bullet::before{content: "";background: rgba(255,255,255,0.4);height: 14px;width: 1px;position: absolute;left: -10px;top: 50%;transform: translateY(-50%)}
.mainService_subPagination{margin: 20px 0 40px}
.mainService_subPagination .swiper-pagination{display: flex;align-items: center;justify-content: center;gap: 8px}
.mainService_subPagination .swiper-pagination-bullet{background: rgba(255,255,255,0.4);width: 11px;height: 11px;min-width: 11px;border-radius: 100%}
.mainService_subPagination .swiper-pagination-bullet-active{background: #FF7AB7;opacity: 1}

.mainService_subPagination .mainService_swiperPagination{display: flex;align-items: center;justify-content: center;gap: 8px}
.mainService_subPagination .swiperPagination_bullet{background: rgba(255,255,255,0.4);width: 11px;height: 11px;min-width: 11px;border-radius: 100%}
.mainService_subPagination .swiperPagination_bullet.active{background: #FF7AB7;opacity: 1}

.mainLikeBox{border-radius: 20px;padding: 23px 20px;position: relative}
.mainLikeBox + .mainLikeBox{margin: 12px 0 0}
.mainLikeBox.piBg{background: rgba(255, 202, 208, 0.5)}
.mainLikeBox.pBg{background: rgba(226, 202, 255, 0.5)}
.mainLikeBox.bBg{background: rgba(182, 223, 255, 0.5)}
.mainLikeBox >img{margin: 0 auto 16px}
.mainLikeTitle{font-weight: 800;font-size: 18px;text-align: center}
.mainLikeText{margin: 8px 0 0;font-size: 14px;color: #3a3a3a;text-align: center}

.mainLikeBox.piBg::before{content: "";width: 32px;height: 31px;background: url(/assets/images/main/main_img_0.svg) no-repeat center;background-size: 100%;position: absolute;right: 30px;top: 0;transform: translateY(-50%)}
.mainLikeBox.pBg::before{content: "";width: 44px;height: 44px;background: url(/assets/images/main/main_img_1.svg) no-repeat center;background-size: 100%;position: absolute;left: 28px;top: 0;transform: translateY(-50%)}
.mainLikeBox.bBg::before{content: "";width: 69px;height: 60px;background: url(/assets/images/main/main_img_2.svg) no-repeat center;background-size: 100%;position: absolute;right: 0;top: 0;transform: translateY(-50%)}
.mainLikeBox.bBg::after{content: "";width: 41px;height: 42px;background: url(/assets/images/main/main_img_3.svg) no-repeat center;background-size: 100%;position: absolute;left: 10px;bottom: 0;transform: translateY(50%)}

.mainSection.wgBg{background: rgba(222, 252, 236, 1)}
.mainDnaTitle{font-size: 18px;font-weight: 800;text-align: center;color: #fff;background: url(/assets/images/main/process_title_bg.svg) no-repeat center;background-size: 278px 50px;width: 278px;padding: 13px 0;margin: 0 auto 20px}
.mainProcessBox{box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);background: #fff;border-radius: 20px;display: flex;align-items: center;justify-content: space-between;gap: 15px;padding: 20px;opacity: 0.6;transition: opacity 0.2s}
.mainProcessBox + .mainProcessBox{margin: 12px 0 0}
.mainProcessBox.active{opacity: 1}
.mainProcess_number{border-radius: 100%;background: #00BE6E;color: #fff;width: 20px;height: 20px;min-width: 20px;line-height: 20px;text-align: center;display: flex;align-items: center;justify-content: center;text-indent: -2px}
.mainProcess_title{color: #00BE6E;font-weight: 800;font-size: 16px}
.mainProcess_text{margin: 3px 0 0;font-size: 13px;font-weight: 700;padding-left: 26px}
.mainProcess_titleBox{display: flex;align-items: center;gap: 6px}

.mainAboutText{color: #fff;font-size: 14px;text-align: center}
.btn_mainAbout{text-align: center;margin: 20px auto 0;max-width: 279px;display: block;font-size: 14px;color: #fff;text-decoration: underline !important;background: #00D684;border-radius: 14px;padding: 16px;width: 100%}

.maingFaqSection{background: #f3f3f3}
.moreBtn{display: flex;align-items: center;justify-content: center;text-align: center;gap: 5px}
.moreBtn_contents{color: #838383;font-size: 13px;font-weight: 700}
.moreBtnBox{display: flex;align-items: center;justify-content: center}
.moreBtn.bColor .moreBtn_contents{color:#000}
.moreBtn.on img{transform: rotate(180deg)}

.mainSection.videoSlideSection{padding-left: 0;padding-right: 0;padding-bottom: 0}
.videoSlideSection .mainSectionTitleBox{padding: 0 20px}
.videoSlideBtnBox{display: flex;align-items: center;justify-content: space-between;padding: 0 20px}
.btn_videoSlide{width: 50%;display: flex;align-items: center;justify-content: center;gap: 6px;font-size: 14px;color: #00BE6E;font-weight: 800;height: 60px;padding: 0 15px;position: relative;line-height: 1}
.btn_videoSlide + .btn_videoSlide::before{content: "";position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 1px;height: 14px;background: #D8D8D8}
.btn_videoSlide.swiper-button-lock{opacity: 0.5;cursor: auto}

.mainFamily{display: flex;align-items: center;justify-content: space-between;padding: 0 20px;gap: 15px;background: #fff;min-height: 50px;position: relative}
.mainFamily::before{content: "";position: absolute;right: 0;top: 0;height: 100%;width: 150px;background: url(/assets/images/main/main_cate_bg.png) no-repeat center;background-size: 100% 100%;z-index: 1}
.mainFamilyList{width: calc(100% - 95px - 15px);overflow-x: auto;display: flex;align-items: center;padding: 10px 0;min-height: 50px}
.mainFamilyBtn{font-size: 14px;font-weight: 800;color: #C7C7C7;padding: 5px 8px;position: relative}
.mainFamilyBtn.active{color: #1E1E1E}
.mainFamilyBtn.active::before{content: "";width: calc(100% - 16px);position: absolute;bottom: 0;left: 50%;transform: translate(-50%,100%);border-radius: 50px;background: #00A05D;height: 4px}
.MainFamily_add{display: flex;align-items: center;justify-content: center;gap: 4px;font-size: 14px;font-weight: 800;color: #fff;background: #FF7AB7;border-radius: 43px;padding: 5px 10px;position: relative;z-index: 2;white-space: nowrap}  

.mainBoard{padding: 60px 0 10px}
.mainFamily + .mainBoard{padding: 20px 0 10px}
.mainBoard_titleBox{padding: 0 20px;text-align: center;position: relative;z-index: 1}
.mainBoard_title{font-size: 22px;text-align: center;font-weight: 800}
.mainBoard_title span{color: #00A05D}
.mainBoard_imgBox img{width: 100%}
.mainBoard .pageBtn{max-width: calc(100% - 40px);margin-left: auto;margin-right: auto}
.mainBoard_down_icon{margin: 14px auto 0}
.mainBoard .barcodeContents{padding: 0 20px 20px}
.mainBoard .barcodeContents.minVar{padding: 30px 20px 80px}
.mainBoard_btnTextBox{padding: 0 20px}
.mainBoard_btnText{text-align: center;font-size: 13px;font-weight: 700;margin: 0 0 16px;color: #838383}
.mainBoard_btnText button,.mainBoard_btnText a{text-decoration: underline;color:#1e1e1e;font-weight: 700;margin: 0 2px}
.mainBoard_btnText b{font-weight: 800;color: #1e1e1e}
.mainBoard_icon{margin: 40px auto 13px}
.mainBoard_warningBox{margin: 0 auto 20px;max-width: calc(100% - 40px);padding: 12px 16px;background: #FFE4F1;border-radius: 14px;display: flex;align-items: center;justify-content: space-between;gap: 10px}
.mainBoard_titleBox + .mainBoard_warningBox{margin-top: 85px}
.mainBoard_warningTextBox{width: 100%}
.mainBoard_warningTitle{color: #FF4FA0;font-size: 13px;font-weight: 800;margin: 0 0 4px}
.mainBoard_warningText{color: #FF4FA0;font-size: 12px}
.mainBoard_warningBox>img{min-width: 16px}
.warningBtnText{color: #FF4FA0;font-size: 13px;line-height: 19px}
.warningBtnText>button{text-decoration: underline;font-weight: 500;color: #FF4FA0}
.mainBoard_text{font-size: 13px;line-height: 22px;font-weight: 700;color: #5d5d5d;margin: 10px 0 0}
.mainBoard_text button,.mainBoard_text a{color: #1E1E1E;text-decoration: underline}

.mainBoard .chk_item{max-width: calc(100% - 40px);margin: 0 auto 20px}
.mainBoard .toastPopup .chk_item{max-width: 100%}

.returnSection{padding: 0 20px}
.returnTitleBox{margin: 30px 0 0}
.returnTitleBox>img{margin: 0 auto 10px}
.returnTitle{font-size: 22px;font-weight: 800;text-align: center}
.returnTitle span{color: #00A05D}
.returnTextItem{display: flex;align-items: flex-start;gap: 10px;margin: 20px 0 0}
.returnTextItem>img{margin: 5px 0 0}
.returnText{font-size: 15px;font-weight: 800}
.returnTextCaption{font-size: 13px;font-weight: 700;color: #5D5D5D;margin: 3px 0 0}
.returnContentsBox{margin: 30px 0 0}
.returnContentsItem{background: #F9F9F9;border-radius: 14px;padding: 15px}
.returnCs_title{color: #3A3A3A;font-size: 15px;font-weight: 800}
.returnCs_phone{color: #3A3A3A;font-size: 13px;font-weight: 700;margin: 2px 0 0}
.returnCs_btn{text-decoration: underline;color: #00A05D;margin: 8px 0 0;font-size: 15px;font-weight: 800}
.btnTextType{color: #838383;font-size: 13px;font-weight: 700;margin: 0 0 10px;text-align: center}
.returnContentsTitleBox{display: flex;align-items: center;justify-content: space-between;margin: 0 0 10px}
.returnContentsTitle{font-size: 16px;font-weight: 800}
.returnAdd_name{font-size: 16px;font-weight: 800}
.returnAdd_phone{font-size: 14px;font-weight: 800;margin: 6px 0 0}
.returnAdd_addr{font-size: 13px;color: #838383;margin: 4px 0 0}
.returnSuccess_title{text-align: center;font-size: 14px;font-weight: 700;color: #3a3a3a;line-height: 25px}
.returnSuccess_title button,.returnSuccess_title a{text-decoration: underline;font-weight: 700}
.returnSuccess_text{margin: 6px 0 0;text-align: center;font-size: 12px;color: #838383;line-height: 20px}
.returnTitleBox + .inputItemBox{margin-top: 40px}
.returnSuccess_info{margin: 20px 0 0}

.returnProductInfo{border: 1px solid #F3F3F3;margin: 0 0 12px;border-radius: 16px;padding: 15px}

/*shop*/
.productListBox{border-radius: 20px;border: 1px solid #F4F4F4;background: #fff;padding: 20px 20px 30px}
.productList_img{background-repeat: no-repeat;background-size: cover;background-position: center;width: 100%;padding: 0 0 63%;margin: 0 auto 20px;border-radius: 16px;background-color: rgba(243, 243, 243, 0.5)}
.productList_name{text-align: center;font-size: 18px;font-weight: 800;color: #3a3a3a}
.productList_info{color: #5d5d5d;font-size: 13px;margin: 5px 0 0;text-align: center}
.productList_price{margin: 14px 0 0;color: #FF4FA0;font-size: 20px;font-weight: 800;text-align: center}
.productList_price span{font-size: 14px;font-weight: 800;margin: 0 3px 0 0}
.productList_saleBox{display: flex;align-items: center;justify-content: center;margin: 5px 0 0}
.productList_sale{font-size: 14px;font-weight: 800;color: #FF4FA0;background: #FFE4F1;border-radius: 6px;line-height: 1;padding: 5px 7px 4px 7px;margin: 0 5px 0 0}
.productList_salePrice{color: #ACACAC;font-size: 14px;font-weight: 700;text-decoration: line-through}

.productList{padding: 74px 0 0}
.btnMinLink{border: 1px solid #D8D8D8;border-radius: 58px;display: flex;gap: 4px;padding: 6px 6px 6px 11px;align-items: center}
.btnMinLink_text{color: #838383;font-size: 13px;font-weight: 700;line-height: 14px}
.productList .swiper{padding: 0 20px}
.productList .swiper-pagination{display: flex;align-items: center;justify-content: center;gap: 8px;margin: 20px 0 0}
.productList .swiper-pagination-bullet{background: #D8D8D8;width: 11px;height: 11px;min-width: 11px;border-radius: 100%}
.productList .swiper-pagination-bullet-active{background: #00BE6E;opacity: 1}
.productListBoxArea{position:relative}
.productListBoxArea .btnMinLink{position:absolute;z-index: 1;top: 20px;left: 16px}
.productListBox.btnMinLinkBox{padding-top:62px}

.productSelBox{padding: 16px 0 0}
.productSelItem{border-bottom: 1px solid #F3F3F3;padding: 0 0 16px}
.productSelItem + .productSelItem{margin: 16px 0 0}
.productSelItem_nameBox{display: flex;align-items: flex-start;justify-content: space-between;gap: 15px}
.productSelItem_name{font-size: 16px;font-weight: 700;color: #5D5D5D}
.productSelItem_priceBox{margin: 2px 0 0;display: flex;align-items: center;justify-content: space-between}
.productSelItem_price{font-size: 16px;font-weight: 800}
.countBox{display: flex;align-items: center;justify-content: center;background: #F6F6F6;border-radius: 21px;padding: 6px}
.countInput{border: 0;background: transparent !important;text-align: center;width: 46px;color: #3a3a3a !important;line-height: normal}
.countInput:focus{border:0 !important}
.productSelPrice{padding: 16px 0 10px}
.productSelPriceDataBox{display: flex;align-items: center;justify-content: space-between;gap: 15px}
.productSelPriceDataBox + .productSelPriceDataBox{margin: 10px 0 0}
.productSelPriceData_name{font-weight: 700;color: #5D5D5D;font-size: 14px}
.productSelPriceData_val{font-weight: 700;color: #5D5D5D;font-size: 14px;text-align: right}
.total .productSelPriceData_name{font-weight: 800}
.total .productSelPriceData_val{font-size: 18px;font-weight: 800;color: #FF4FA0}

.notOptionText{text-align: center;color: #C7C7C7;font-size: 14px;padding: 10px 0}

.productItemBox{padding: 20px}
.productItemBox.multipleBox{padding: 12px 0}
.productItemBox.multipleBox:last-child{padding-bottom: 0}
.productItem{display: flex;align-items: center;gap: 10px}
.productItemImg{min-width: 72px;height: 72px;width: 72px;border-radius: 16px;background-color: #f3f3f3}
.productItem_infoBox{width: calc(100% - 82px)}
.productItem_name{font-weight: 800;color: #5D5D5D;font-size: 14px}
.productItem_options{margin: 2px 0 0;color: #acacac;font-size: 12px}
.productItem_price{margin: 4px 0 0;font-size: 15px;font-weight: 800}
.productItem_price.pColor{color: #FF4FA0}
.productItem_salePrice{display: flex;align-items: center;gap: 4px;margin: 4px 0 0}
.productItem_sale{font-weight: 800;font-size: 13px;color: #FF4FA0;background: #FFE4F1;border-radius: 6px;padding: 2px 6px}
.productItem_basicPrice{font-size: 12px;color: #C7C7C7;text-decoration: line-through}
.productItemTitleItem{display: flex;align-items: center;justify-content: space-between;gap: 10px}
.productItemTitleBox{margin: 0 0 10px}
.productItemTitle{font-size: 16px;font-weight: 800}
.productItemTitleCaption{color: #838383;font-size: 13px;margin: 4px 0 0}
.productDetailData img{max-width: 100%}
.productDetailData *{font-weight: revert;color: revert;font-family: revert;font-size: revert;line-height: 140%}

.orderAddrBox + .productItemTitleBox{margin-top:25px}
.orderAddrBox + div{margin-top:25px}
.productOrder_announcement{padding: 10px 20px;font-size: 13px;color: #5D5D5D;font-weight: 700}

.notCartList{padding: 130px 0;display: flex;align-items: center;justify-content: center;text-align: center;flex-direction: column;gap: 14px;color: #C7C7C7;font-size: 15px;font-weight: 700}
.notCartList img{margin: 0 auto}

.cartList_head{display: flex;align-items: center;justify-content: space-between;gap: 10px}
.cartList_head .chk_item label{width: 100%}
.cartList_head .chk_item_text{font-weight: 800;font-size: 14px}

.cartListItem{padding: 15px 0;border-bottom: 1px solid #F3F3F3;display: flex;gap: 10px;align-items: flex-start}
.cartListProductItem{width: calc(100% - 30px)}
.cartListProductItem_infoBox{display: flex;align-items: flex-start;gap: 10px}
.cartListProductItem_info{display: flex;align-items: center;gap: 10px;width: calc(100% - 17px)}
.cartListProductItem_img{background-color: #F3F3F3;border-radius: 16px;width: 59px;height: 59px;min-width: 59px}
.cartListProductItem_infoBox .btn_productDel{margin: 10px 0 0}
.cartListProductItem_nameBox{width: calc(100% - 69px)}
.cartListProductItem_name{color: #5D5D5D;font-size: 15px;font-weight: 700;width: 100%;text-overflow: ellipsis;white-space: nowrap;overflow: hidden}
.cartListProductItem_option{font-size: 13px;color: #ACACAC;margin: 2px 0 0}
.cartListProductItem_priceBox{display: flex;align-items: center;justify-content: space-between;gap: 10px}
.cartListProductItem_price{font-size: 15px;font-weight: 800}

.cartList_chkBox{margin: 19.5px 0 0}
.cartList_chkBox label{position: relative;padding: 0 0 0 20px;width: calc(100% - 34px);font-weight: 700}
.cartList_chkBox label::before{content: "";position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 20px;height: 20px;background: url(/assets/images/basic/chk_off.svg) no-repeat center;background-size: 100%}    
.cartList_chkBox input:checked + label::before{background-image: url(/assets/images/basic/chk_on.svg)}

.cartPriceBox{margin: 16px 0 0}
.orderPriceBox .total .productSelPriceData_name{font-size: 16px}
.orderPriceBox .productSelPriceDataBox.total + .productSelPriceDataBox{margin: 16px 0 0}

.orderAddrBox{padding: 20px 16px;background: #F9F9F9;border-radius: 8px}
.orderAddrInfo{font-size: 16px;font-weight: 800}
.orderAddrAddr{font-size: 13px;color: #838383;margin: 5px 0 0}
.orderAddrAddr:first-child{margin-top: 0}

.orderComSection{padding: 20px;text-align: center}
.orderComSection>img{margin: 0 auto 20px}
.orderCom_title{font-size: 20px;font-weight: 800;color: #5d5d5d}
.orderCom_text{margin: 10px 0 0;font-size: 14px;color: #838383}
.orderCom_text span{color: #00A05D;font-weight: 500}

/*report*/
.reportInfoSection{padding: 129px 20px 40px}
.familyList>div{width: 100vw;margin: 0 0 0 -20px;padding: 0 20px;display: flex;gap: 6px;align-items: flex-start;overflow-x: auto}
.familyItem{min-width: 105px;width: 105px}
.familyItem_img{background-repeat: no-repeat;background-size: 60px 60px;background-position: center;width: 60px;height: 60px;border-radius: 100%;position: relative;border: 1px solid transparent;margin: 0 auto 8px}
.familyItem_img::before{content: "";position: absolute;top: -2px;left: -2px;width: 62px;height: 62px;border-radius: 100%;background: rgba(255,255,255,0.8)}
.familyItem_img>img{position: absolute;right: 0;bottom: 0;z-index: 1}
.familyItem_name{font-size: 11px;font-weight: 800;color: #5d5d5d}
.familyItem_name.normal{text-overflow: ellipsis;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical}
.familyItem_name.gColor{color: #838383 !important}
.familyItem.active .familyItem_name,.familyItem.sel .familyItem_name{color: #1e1e1e}
.familyItem.sel .familyItem_img{border-color: #0BCD70}
.familyItem.active .familyItem_img::before,.familyItem.sel .familyItem_img::before{background-color: transparent}

.cardSection{padding: 40px 20px}
.cardSection + .cardSection{padding-top: 0}
.reportTitle{font-size: 18px;font-weight: 800;margin: 0 0 20px}
.cardList{width: 100vw;margin: 0 0 0 -20px;padding: 0 20px;gap: 10px;display: flex;align-items: flex-start;overflow-x: auto}
.cardItem{width: 110px;min-width: 110px}
.cardItem_img{background-repeat: no-repeat;background-position: center;background-size: cover;background-color: rgba(217, 217, 217, 0.5);border-radius: 10px;overflow: hidden;width: 100%;padding: 0 0 136%}
.cardItem_text{font-weight: 700;color: #5d5d5d;font-size: 13px;text-align: center;margin: 8px 0 0;text-overflow: ellipsis;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical}

.userReportArea{background: #fff;padding: 20px 20px 25px;border-radius: 20px;margin: 20px 0 0}
.userReport_titleBox{display: flex;align-items: center;justify-content: space-between;gap: 10px;margin: 0 0 16px}
.userReport_name{font-size: 15px;font-weight: 800}
.btn_download{background: #00A05D;display: flex;align-items: center;justify-content: center;gap: 8px;padding: 6px 9px;border-radius: 100px;color: #fff;font-size: 13px;font-weight: 800}
.userReport_img{position: relative;background-color: rgba(243, 243, 243, 0.5);width: 100%;border-radius: 11px;overflow: hidden;margin: 0 0 16px}
.userReport_img img{width:100%}
.reportPercentItemArea + .reportPercentItemArea{margin: 25px 0 0}
.reportPercentItem_info{display: flex;align-items: center;justify-content: space-between;gap: 15px;margin: 0 0 4px}
.reportPercentItem_name{color: #5D5D5D;font-size: 12px;font-weight: 700}
.reportItemCaption{font-size: 12px;font-weight: 800;padding: 4px;line-height: 1;border-radius: 4px;text-align: center;background: #F3F3F3;color: #5d5d5d}
.rColor .reportItemCaption{background: #FFE4F1;color: #FF4FA0}
.yColor .reportItemCaption{background: #FFF9CF;color: #FFB802}
.gColor .reportItemCaption{background: #E5F9F1;color: #00BE6E}
.reportPercentItem_bg{background: rgba(236, 236, 236, 1);border-radius: 29px;height: 11px;width: 100%;overflow: hidden}
.reportPercentItem{width: 80%;display: block;height: 100%;border-radius: 29px;background-color: #838383}
.rColor .reportPercentItem{background: #FF7AB7}
.yColor .reportPercentItem{background: #FDE614}
.gColor .reportPercentItem{background: #00BE6E}
.captureBox{position: fixed;top: 0;left: 0;width: 100%;transform: translate(-101%,-101%)}
.captureBox.cardCaptureBox{max-width: unset;width:auto}

.captureBox .reportPercentItem_info{margin: 0 0 8px}
.captureBox .reportItemCaption{line-height: 1;display: flex;align-items: center;justify-content: center}
.captureBox .reportItemCaption span{transform: translateY(-7px);height: 13px}
.captureBox .cardImg{border-radius: 0;width:310px}

.reportDataSection{background: #F4F5F6;padding: 40px 20px}
.reportDataAlignBox{display: flex;justify-content: flex-end;margin: 0 0 20px}
.alignBtn{display: flex;align-items: center;justify-content: center;gap: 5px}
.alignBtn_contents{color: #5d5d5d;font-size: 13px;font-weight: 800}
.reportDataList{position: relative}
.reportDataListItem{display: flex;align-items: center;justify-content: space-between;padding: 12px;border-radius: 10px;background: #fff;width: 100%;gap: 15px;min-height: 66px}
.reportDataListItem + .reportDataListItem{margin: 14px 0 0}
.reportDataListItem_info{display: flex;align-items: center;justify-content: flex-start;gap: 10px;width: calc(100% - 100px)}
.reportDataListItem_info>img{min-width: 42px}
.reportDataListItem_name{font-size: 14px;font-weight: 800;width: calc(100% - 52px);text-align: left}
.reportDataListItemCaption span{display: flex;gap: 3px;align-items: center}
.reportDataListItemCaption span i{white-space: nowrap}

.reportDataCover{position: absolute;top: 0;left: 0;border-radius: 10px;background: rgba(255,255,255,0.9);width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;flex-direction: column;gap: 14px;padding: 20px}
.reportDataCover_text{font-size: 16px;font-weight: 800;color: #3a3a3a;text-align: center}
.userReport_sampleName{background: #00BE6E;border-bottom-left-radius: 7px;border-bottom-right-radius: 7px;font-size: 16px;color: #fff;font-weight: 800;padding: 4px 12px;margin: -20px 0 0}

.cardImg_section{background: url(/assets/images/report/cardBg.png) no-repeat center;background-size: cover;width: 100%;padding: 54px 55px}
.bgImgItem{background-repeat: no-repeat;background-size: cover;background-position: center}
.cardImg{padding: 0 0 136%;border-radius: 10px;position: relative}
.cardInfo_section{padding: 25px 20px 50px}
.capture_cardInfo_section{padding: 25px 20px 35px}
.cardInfo_name{font-size: 22px;font-weight: 800;text-align: center}
.cardInfo_text{margin: 6px 0 23px;font-size: 15px;font-weight: 700;text-align: center}
.reportDetailtext{font-size: 13px;line-height: 22px}
.reportDetailSubTitleBox{padding: 0 20px 10px}
.reportDetailSubTitle{font-size: 16px;font-weight: 800}
.otherCard{padding: 10px 20px 40px;display: flex;flex-wrap: wrap;justify-content: space-between;align-items: flex-start;gap: 10px}
.otherCard .cardItem{width: calc(50% - 5px);min-width: calc(50% - 5px)}
.otherCard .cardItem_text{-webkit-line-clamp: 1;font-size: 14px}
.card_capture{position: absolute;top: 10px;right: 10px;border-radius: 100%;padding: 6px;}
.card_capture img{width: 16px}

.reportDetailTypeTitleBox{padding: 74px 20px 12px;display: flex;align-items: center;gap: 6px}
.reportDetailTypeTitleBox>img{width: 26px;min-width: 26px}
.reportDetailTypeTitle{font-size: 18px;font-weight: 800;color: #3a3a3a}
.reportDetailInfoBox{width: calc(100vw - 40px);margin: 0 auto;border-radius: 10px;background: #f4f4f4;padding: 20px;display: flex;gap: 24px;align-items: center}
.reportDetailInfo_imgBox{position: relative;width: 44px;min-width: 44px}
.reportDetailInfo_imgBox .subIcon{position: absolute;right: 0;bottom: 0}
.reportDetailInfoText{font-size: 13px;font-weight: 700}
.reportDetailInfoText .reportDetailInfoTextName{margin: 0 2px 0 0}
.reportDetailInfoText span{color: #838383}
.reportDetailInfoText .rColor{color: #FF4FA0}
.reportDetailInfoText .yColor{color: #FFB802}
.reportDetailInfoText .gColor{color: #00BE6E}
.reportDetailTagBox{display: flex;flex-wrap: wrap;align-items: flex-start;gap: 8px 6px;padding: 16px 20px 0}
.reportDetailTag{border-radius: 6px;background: #00BE6E;font-size: 12px;font-weight: 700;color: #fff;padding: 5px 10px;max-width: 100%}
.reportDetailSection{border-top: 6px solid #F3F3F3;padding: 20px 20px 30px;margin: 20px 0 0}
.reportDetailSection>img{margin: 0 auto 20px;width: 100%}
.reportDetailtext + .reportDetailSubTitleBox{padding: 30px 0 10px}
.reportDetailtext + .reportDetailTextArea .reportDetailSubTitleBox{padding: 30px 0 10px}
.reportDetailTextArea + .reportDetailTextArea .reportDetailSubTitleBox{padding: 30px 0 10px}
.reportDataSection .reportDetailSubTitleBox{padding: 0 0 10px}

.chemi_characterBox{background: url(/assets/images/img/dna_bgImg.svg) no-repeat center;height: 100px;display: flex;align-items: center;justify-content: center;gap: 50px;margin: 0 0 30px}
.chemi_characterImg{border: 2px solid #fff;border-radius: 100%;width: 60px;height: 60px;min-width: 60px;position: relative}
.chemi_characterImg img{position: absolute;right: 0;bottom: 0}
.chemi_characterName{font-size: 12px;margin: 5px 0 0;font-weight: 800}
.chemi_imgBox{border-radius: 20px;background: #00be6e;padding: 20px 14px;text-align: center}
.chemi_imgTitle{font-size: 15px;color: #fff;font-weight: 700;line-height: 24px}
.chemi_imgTitle b{font-weight: 800}
.chemi_imgTitle span{font-size: 15px}
.chemiCountText{display: block;margin: 10px 0 0}
.chemi_imgBox>img{margin: 16px auto 20px;border-radius: 11px;max-width: 100%}
.chemi_imgCount{font-size: 15px;font-weight: 700;color: #fff}
.chemiList{padding: 40px 20px;background: #F4F5F6;min-height: calc(var(--vh, 1vh) * 60)}
.chemiList_title{font-size: 16px;font-weight: 800}
.chemiList_typeBox{margin: 20px 0 0;display: flex;align-items: center;flex-wrap: wrap;gap: 8px}
.chemiListTap{border-radius: 43px;background: #EAECED;padding: 7px 10px;display: flex;align-items: center;gap: 4px;color: #C7C7C7;font-size: 14px;font-weight: 700;color: #3a3a3a}
.chemiListTap.active.hi{background: #FFD1E6}
.chemiListTap.active.lo{background: #CEF0FF}
.chemiListBox{margin: 20px 0 0;position: relative}
.chemiItem{display: flex;align-items: center;justify-content: space-between;gap: 20px;background: #fff;padding: 20px 15px;border-radius: 10px;width: 100%;margin: 12px 0 0}
.chemiItemInfo{text-align: left;width: calc(100% - 20px - 55px)}
.chemiItem_categoryBox{display: flex;align-items: center;gap: 6px;font-size: 15px;font-weight: 700;color: #5D5D5D}
.chemiItemInfo_text{font-size: 18px;font-weight: 800;margin: 10px 0 0}
.chemiItem_icon{text-align: center;font-weight: 800;font-size: 10px;color: #FC4F98;min-width: 55px}
.chemiItem_icon img{margin: 0 auto 3px}
.chemiItem_icon.lo{color: #5ACEFF}
.link_familyAdd{position: fixed;right: 20px;bottom: 20px;display: flex;align-items: center;justify-content: center;gap: 10px;padding: 13px;border-radius: 100px;background: #FF7AB7;box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25)}
.link_familyAddText{font-size: 12px;font-weight: 700;color: #fff;transform: translateY(2px)}
.link_familyAdd.active .link_familyAddText{display: none}

.chemi_characterSample{display: flex;align-items: center;justify-content: center;gap: 16px;flex-direction: column;padding: 0 0 30px}
.chemi_characterSample_title{color: #00A05D;font-size: 16px;font-weight: 800}
.chemi_characterSample_link{border-radius: 39px;min-width: 160px;padding: 13px 15px;color: #00A05D;font-size: 15px;font-weight: 800;border: 1px solid #00A05D;background: #fff}

.chemiSelItem{display: flex;align-items: center;gap: 7px;color: #5D5D5D;font-size: 16px;font-weight: 700}
.selPopup_sel.active .chemiSelItem{color: #00A05D}
.selPopup_selSub_category{padding: 15px 20px;text-align: left;border: 0;display: block;font-size: 15px;font-weight: 700;background: #F4F5F6;width: 100vw;transform: translateX(-20px)}
.selPopup_selSub_lineItem{background: #F3F3F3;height: 6px;width: 100vw;transform: translateX(-20px)}
.chemiSelItemImg{width: 42px;height: 42px;min-width: 42px;border-radius: 100%}
.chemiSelItem>span{width: calc(100% - 49px)}

.chemiDetail_name{font-size: 14px;font-weight: 700;color: #fff}
.chemiDetail_title{font-weight: 800;font-size: 22px;color: #fff;margin: 15px 0 0}
.chemiDetail_countBox{margin: 30px 0 0}
.chemiDetail_count{font-size: 16px;font-weight: 800}
.chemiDetail_count>span{color: #00BE6E}

.chemiDetailListItem{margin: 13px 0 0}
.chemiDetailListItem{display: flex;align-items: center;justify-content: space-between;width: 100%;background: #fff;border-radius: 10px;padding: 18px 0}
.chemiDetailListItem_cateBox{width: 41%;position: relative;padding: 0 20px}
.chemiDetailListItem_cateBox::before{content: "";position: absolute;top: 5px;right: 0;width: 2px;background: rgba(243, 243, 243, 0.5);height: calc(100% - 10px);border-radius: 6px}
.chemiDetailListItem_cate{margin: 0 0 10px;font-size: 13px;font-weight: 800}
.chemiDetailListItem_cateIconBox{display: flex;align-items: center;justify-content: center;gap: 4px;color: #5D5D5D;font-size: 14px;font-weight: 700}
.chemiDetailListItem_cateIconBox img{width: 22px;min-width: 22px}
.chemiDetailListItem_typeBox{width: 59%;display: flex;align-items: center;justify-content: space-between;gap: 20px}
.chemiDetailListItem_typeItem{width: calc(50% - 10px);padding: 0 0 0 20px;text-align: center}
.chemiDetailListItem_typeItem:last-child{padding: 0 20px 0 0}
.chemiDetailListItem_nickName{margin: 0 0 8px;font-size: 13px;font-weight: 700;color: #838383}
.chemiDetailListItem_type{display: inline-block}

.familyManagArea + .familyManagArea{margin: 30px 0 0}
.familyManag_titleBox{margin: 0 0 15px;display: flex;align-items: center;justify-content: space-between;gap: 20px}
.familyManag_title{font-size: 16px;font-weight: 800;text-align: left}
.familyManag_titleCaption{color: #5D5D5D;font-size: 13px;margin: 0 0 15px}
.familyManagItem{display: flex;align-items: center;justify-content: space-between;gap: 20px;border-radius: 10px;background: #fff;padding: 12px 15px;margin: 12px 0 0}
.familyManagItem_info{display: flex;align-items: center;gap: 10px;text-align: left}
.familyManagItem_img{width: 50px;min-width: 50px;height: 50px;border-radius: 100%}
.familyManagItem_name{font-size: 14px;font-weight: 800;color: #5D5D5D}
.familyManagItem_age{color: #838383;font-size: 13px;margin: 1px 0 0}
.familyManagItem_btnBox>div{display: flex;align-items: center;justify-content: flex-end;text-align: right;gap: 10px}
.familyManagItem_btn{font-size: 13px;font-weight: 700;color: #5D5D5D;text-decoration: underline;text-align: right}
.familyManag_titleBtn{font-size: 14px;font-weight: 800;color: #fff;padding: 7px 10px;display: flex;align-items: center;justify-content: center;gap: 4px;border-radius: 43px;background: #00A05D;line-height: 1}
.familyManag_titleBtn img{width: 10px}
.familyManagItem.notBarcode{flex-direction: column;align-items: flex-start;gap: 14px}
.familyManagItem.notBarcode .familyManagItem_btnBox{width: 100%}
.babyProfile{width: 84px;height: 84px;border-radius: 100%;margin: 30px auto}

.spouseAddInfoArea{padding: 30px 0 0;text-align: left}
.spouseAddInfo_title{font-size: 20px;font-weight: 800}
.spouseAddInfo_title span{color: #00A05D}
.spouseAddInfo_infoBox{margin: 20px 0 0;background: #EFFDF5;padding: 20px;border-radius: 10px}
.spouseAddInfo{display: flex;align-items: center}
.spouseAddInfo_name{font-size: 16px;font-weight: 800}
.spouseAddInfo>span{display: block;width: 1px;height: 4px;background: #ACACAC;margin: 0 5px}
.spouseAddInfo_nickName{color: #5D5D5D;font-size: 13px;font-weight: 700}
.spouseAddInfo_number{margin: 6px 0 0;font-size: 18px;font-weight: 800}
.spouseAcceptanceText{font-size: 15px;font-weight: 700;line-height: 24px;padding: 15px 0 10px;text-align: center}
.spouseAddInfo_text{padding: 16px 0;font-size: 13px;color: #707070;text-align: center}

.inspectionStatusInfo_title{font-size: 16px;font-weight: 800;margin: 0 0 15px}
.inspectionStatusItem{background: #F3F3F3;border-radius: 10px;width: 100%;display: flex;align-items: center;gap: 14px;padding: 15px;position: relative}
.inspectionStatusItem + .inspectionStatusItem{margin: 20px 0 0}
.inspectionStatusItem.active{background: #00BE6E}
.inspectionStatusItem + .inspectionStatusItem::before{content: "";position: absolute;width: 1px;height: 20px;background: #d8d8d8;top: 0;left: 31px;transform: translateY(-100%)}
.inspectionStatusItem_name{font-size: 14px;font-weight: 800;color: #838383}
.inspectionStatusItem_date{font-size: 12px;color: #838383;margin: 1px 0 0}
.inspectionStatusItem.active .inspectionStatusItem_name,.inspectionStatusItem.active .inspectionStatusItem_date{color:#fff}

.surveyCom_img{margin: 0 auto 15px;padding: 20px 0 0}
.surveyCom_text{text-align: center;font-weight: 800;font-size: 20px;color:#3a3a3a;line-height: 30px}

.surveyCover{padding: 30px 0;text-align: center}
.surveyCover_title{text-align: center;font-size: 22px;font-weight: 800}
.surveyCover_title>span{color: #00A05D}
.surveyCover_time{margin: 16px 0 0;background: #F4F4F4;border-radius: 8px;display: inline-flex;align-items: center;justify-content: center;gap: 5px;padding: 9px;color: #838383;font-size: 13px;font-weight: 700}
.surveyCover>img{margin: 20px auto 0}
.surveyCover_caption{margin: 30px 0 0;padding: 12px;font-size: 12px;font-weight: 400;text-align: left;color: #FF4FA0;line-height: 20px;border-radius: 14px;background: #FFE4F1}
.surveyCover_caption b{display: block;font-size: 13px;font-weight: 800;color: #FF4FA0;margin: 0 0 2px}

.surveyTitleBox{padding: 20px 0 35px}
.surveyTitle_count{font-size: 17px;font-weight: 800;color: #ACACAC;margin: 0 0 4px}
.surveyTitle{color: #333;font-size: 20px;font-weight: 800}
.surveyTitle_caption{color: #5D5D5D;font-size: 13px;margin: 10px 0 0}
.surveyTitle span{color: #00A05D}

.surveyTitleBox + .inputItemBox{margin-top: 0}
.surveyChk_itemBox + .surveyChk_itemBox{margin: 12px 0 0}
.surveyChk_item label{border: 1px solid #F3F3F3;border-radius: 8px;display: block;padding: 15px 15px 15px 44px;background: url(/assets/images/basic/chk_off.svg) no-repeat left 15px top 15px}
.surveyChk_item_text{font-weight: 700;font-size: 14px;color: #ACACAC}
.surveyChk_item input:checked + label{background-image: url(/assets/images/basic/chk_on.svg);border-color: #00A05D;background-color: #EFFDF5}
.surveyChk_item input:checked + label .surveyChk_item_text{font-weight: 800;color: #3A3A3A}

.surveyChk_item + .inputItemBox{margin-top: 12px}
.inputItemBox + .surveyChk_item{margin-top: 12px}
.surveyChk_item + .inputItemBox + .inputItemBox{margin-top: 12px}
.typeLine .textareaItem{border: 1px solid #838383}
.typeLine .inputItem{border: 1px solid #838383}

/*addbarcode*/
.popupTermsChkBox{margin: 20px 0 0}
.toastBasicContents + .btnMinLink{margin: 16px auto 0}
.addBarcodeSection{padding: 30px 20px 0}
.barcodeHeader{text-align: center}
.barcodeHeaderTitle{text-align: center;font-weight: 800;font-size: 20px}
.barcodeHeaderTitle span{color: #00A05D}
.mainBoard .barcodeHeader{padding: 0 20px}
.barcodeHeader>img{margin: 0 auto 14px}
.barcodeHeader_btnBox{display: flex;align-items: center;justify-content: center;gap: 6px;margin: 20px 0 50px}
.barcodeHeaderLink{font-size: 13px;font-weight: 700;text-decoration: underline;text-align: center;margin: 10px auto 0;display: inline-block}
.barcodeNumberInput{position: relative}
.barcodeNumberInput .inputItem{padding-left: 58px;padding-right: 53px}
.barcodeNumber_text{font-size: 15px;position: absolute;left: 15px;top: 50%;transform: translateY(-50%)}
.btnBarcodeNumber{position: absolute;right: 15px;top: 50%;transform: translateY(-50%)}
.btnBarcodeChkOpen{min-height: 20px;background: url(/assets/images/basic/chk_off.svg) no-repeat left center;background-size: 20px;font-size: 13px;padding-left: 28px;margin:30px 0 0;text-align: left}
.btnBarcodeChkOpen.active{background-image: url(/assets/images/basic/chk_on.svg)}

.btnToolTipBox{margin: 0 0 0 5px;display: inline}
.btnToolTip{transform: translateY(2px)}
.toolTipTextBox{background: #F4F5F6;position: absolute;z-index: 1;left: 0;bottom: -6px;transform: translateY(100%);display: flex;align-items: flex-start;gap: 15px;padding: 10px 10px 10px 16px;border-radius: 6px;display: none}
.toolTipTextBox.active{display: flex}
.toolTipText{font-size: 10px;line-height: 15px}
.toolTipClose img{width:20px}

.bacodeQr{position: absolute;left: 0;top: 0;width: 100%;height: 100%}
.qrCover{position: relative;left: 0;top: 0;width: 100%;height: 100%;z-index: 1;display: flex;flex-wrap: wrap;align-items: flex-start}
.qrCover .t{height: calc(50% - 110px);width: 100%;background: rgba(0, 0, 0, 0.6)}
.qrCover .l{height: 220px;min-width: calc(50vw - 180px);background: rgba(0, 0, 0, 0.6)}
.qrCover .c{min-width: 360px;height: 220px;position: relative}
.qrCover .r{height: 220px;min-width: calc(50vw - 180px);background: rgba(0, 0, 0, 0.6)}
.qrCover .b{height: calc(50% - 110px);background: rgba(0, 0, 0, 0.6);width: 100%}
.qrCover .c span{width: 40px;height: 40px;position: absolute;background: url(/assets/images/board/lt.svg) no-repeat center;background-size: 100%}
.qrCover .c .clt{top: 0;left: 0}
.qrCover .c .crt{top: 0;right: 0;background-image: url(/assets/images/board/rt.svg)}
.qrCover .c .clb{bottom: 0;left: 0;background-image: url(/assets/images/board/lb.svg)}
.qrCover .c .crb{bottom: 0;right: 0;background-image: url(/assets/images/board/rb.svg)}
.qrCover_centerCover{display: flex;justify-content: center;min-height: 220px}

.qrBox{position: absolute;left: 50%;top: 50%;width: 100%;height: 100%;transform: translate(-50%, -50%)}
#qrItem video{display: block;width: 100% !important;height: 100% !important}
/* #qrItem video{display: block;width: 100% !important;height: 100% !important;object-fit: cover !important} */
#qrItem canvas{display: none;width: 100% !important;height: 100% !important}
/* #qrItem video{display: block;width: 100% !important}
#qrItem canvas{width: 100% !important} */
#qrItem>div{display: none !important}
#qrItem{width:100%;height: 100%}
.qrCoverText{font-size: 15px;color: #fff;position: absolute;left: 50%;text-align: center;top: calc(50% - 163px);transform: translate(-50%,-100%);width: 100%}

/*errpage*/
.pageErrBox{padding: 120px 20px}
.pageErrBox>img{margin: 0 auto 20px;min-width: 50px}
.pageErrTitle{text-align: center;font-size: 18px;font-weight: 800;color: #3a3a3a;line-height: 26px}
.pageErrText{color: #838383;font-size: 14px;line-height: 22px;text-align: center;margin: 16px 0 0}

/*orderList*/
.orderList_alignBox{padding: 17px 0 13px;display: flex;align-items: center;justify-content: flex-end}
.orderList_notList{min-height: calc(var(--vh, 1vh) * 70);display: flex;align-items: center;justify-content: center;flex-direction: column;gap: 16px;font-size: 15px;font-weight: 700;color: #C7C7C7;padding: 50px 0}

.orderListItem + .orderListItem{margin: 12px 0 0}
.orderDetailItemBox + .orderDetailItemBox{margin: 12px 0 0}
.orderListItem{background: #fff;border-radius: 20px;padding: 16px}
.orderListItem_numberBox{display: flex;align-items: flex-start;gap: 4px;padding-right: 30px;background: url(/assets/images/basic/move.svg) no-repeat right top;background-size: 22px;width: 100%;text-align: left}
.orderDetailItem .orderListItem_numberBox{background: transparent;cursor: auto}
.orderListItem_number{color: #3A3A3A;font-size: 14px;font-weight: 800}
.orderListItem_date{font-size: 12px;font-weight: 700;color: #838383;margin: 1px 0 0}
.orderCaption{margin: 12px 0 0;border-radius: 4px;padding: 3px 4px 1px;font-size: 12px;font-weight: 800;display: inline-block}
.orderCaption.gColor{color: #00BE6E;background: #E5F9F1}
.orderCaption.rColor{color: #FF4FA0;background: #FFE4F1}
.orderCaption.yColor{color: #FFB802;background: #FFF9CF}
.orderListItem .productItem{margin: 12px 0 0}
.orderListTextBox{background: #F4F5F6;border-radius: 8px;padding: 15px;margin: 12px 0 0}
.orderListText_title{font-size: 12px;font-weight: 700;line-height: 19px}
.orderListText_text{color: #ACACAC;font-size: 12px;line-height: 19px}
.orderListBtnBox{margin: 16px 0 0}
.orderListBtnItem{margin: 16px 0 0}
.orderTransportNumber{margin: 16px 0 0;font-size: 13px;color: #5D5D5D}
.orderListItem.orderDetailItem{border: 1px solid #F3F3F3}
.orderDetail_reDelivery_titleBox{padding: 0 0 20px;text-align: center}
.orderDetail_reDelivery_titleBox img{margin: 0 auto 10px}
.orderDetail_reDelivery_title{text-align: center;font-size: 22px;font-weight: 800}
.orderDetail_reDelivery_title span{color: #00A05D}
.orderDetail_reDelivery_titleLink{font-size: 13px;font-weight: 700;text-decoration: underline;text-align: center;margin: 10px auto 0;display: inline-block}
.orderDetailReasonText{font-size: 13px;margin: 20px 0 0}
.orderDetailReasonText.rColor{color: #F44D4D}
.orderRefundText{font-size: 13px;color:#5D5D5D;margin: 13px 0 0}

/*return*/
.returnBarcode_section{margin: 30px 0 0}
.returnBarcode_titleBox{display: flex;align-items: center;justify-content: space-between;gap: 15px}
.returnBarcode_title{font-weight: 800;font-size: 16px}
.returnBarcode_titleCaption{margin: 10px 0 0;font-size: 13px;color: #838383;line-height: 20px}
.returnBarcode_contents{margin: 20px 0 0}
.returnBarcode_contents>img{width: 100%;border-radius: 10px}
.returnBarcode_itemDelBox{display: flex;align-items: center;justify-content: space-between;gap: 10px}
.returnBarcode_itemDelBox .inputItemBox{width: calc(100% - 34px)}
.returnBarcode_itemDel{margin: 20px 0 0}

.scrollCover{position: absolute;left: 0;top: 0;width: 100%;height: 100%;z-index: 30}
.scrollCoverBox{position: relative}

/*반응형*/
.pc_section{max-width: 100%;padding: 0}
.pc_page,.pc_right_shadow{display: none}
#root{width:100%;min-width: 0;box-shadow: none}

@media (min-width:1000px){
  body{background-color: #00BE6E}
  
  .pc_section{max-width: 1000px;width: 100%;margin: 0 auto;padding-left: 500px}
  .pc_cover{background-color: #00BE6E;width: 100%;overflow-x: hidden}
  .pc_page{width: 501px;min-width: 500px;display: flex !important;flex-direction: column;align-items: center;justify-content: center;gap: 55px;padding: 20px;position: fixed;top: 0;right: 50%;height: 100%;z-index: 9999;background-color: #00BE6E;overflow: hidden}
  .pc_page_title{text-align: center;color: #fff;font-size: 20px;font-weight: 700}
  .pc_page_shadow{position: absolute;right: 0;height: 100%;width: 500px;transform: translateX(500px);box-shadow: 0 0 30px 0 rgba(0,0,0,0.20)}

  .pc_right_shadow{display: block !important;width: 501px;min-width: 500px;background-color: #00BE6E;overflow: hidden;position: fixed;top: 0;height: 100%;left: calc(50% + 500px);z-index: 9999}
  .pc_right_shadow span{position: absolute;left: 0;height: 100%;width: 500px;transform: translateX(-500px);box-shadow: 0 0 30px 0 rgba(0,0,0,0.20)}

  #root{width: 500px;min-width: 500px;background: #fff;box-shadow: 0 0 30px 0 rgba(0,0,0,0.15)}

  .header{max-width: 500px;left: 50%}
  .pageBtn_box{max-width: 500px;left: 50%}
  .sideMenu{max-width: 500px;left: calc(50% - 500px)}
  .sideMenu.active{left: 50%}
  .bottomErrMsg{transform: translateX(40px)}
  .popup{max-width: 500px;left: 50%}
  .popup .popupSetion{transform: translate(calc(-50% + 250px),-50%)}
  .toastPopup{max-width: 500px;left: 50%}
  .rightPopup{max-width: 500px;right: inherit;left: 50%}
  .headerTapBox{max-width: 500px;left: 50%}
  .bgSectionCover{max-width: 500px;left: 50%}
  .selPopup{max-width: 500px;left: 50%}
  .link_familyAdd{right: calc(50% - 480px)}
  .btn_csService{right: calc(50% - 480px)}
  .rightPopup .pageBtn_box{left: 0}
  .captureBox{max-width: 500px}
  .pageSizing.fixedPage{max-width: 500px;left: 50%}

  .qrCover .l{min-width: calc(250px - 180px)}
  .qrCover .r{min-width: calc(250px - 180px)}
  .categoryBox{width: 500px}
  .mainVideoBox.fullSize{width: 500px}
  .familyList>div{width: 500px}
  .reportDetailInfoBox{width: calc(500px - 40px)}
  .inputFileList{width: 500px}
  .selPopup_selSub_category{width: 500px}
  .selPopup_selSub_lineItem{width: 500px}
  .cardList{width: 500px}
  .subLine.pageFull{width: 500px}
  
  .toastPopup .bottomErrMsg{left: 0}

  #qrItem video{object-fit: cover !important}
}
@media (max-width:1800px){

}
@media (max-width:1680px){
	
}
@media (max-width:1600px){

}
@media (max-width:1440px){

}
@media (max-width:1366px){

}
@media (max-width:1280px){

}
@media (max-width:1152px){

}
@media (max-width:1024px){

}
@media (max-width:960px){
    
}
@media (max-width: 720px){
    
}
@media (max-width: 650px){
    
}
@media (max-width: 550px){
}
@media (max-width: 490px){
}
@media (max-width: 400px){
}
@media (max-width: 340px){
}
/*//*/